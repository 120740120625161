<template>
  <div class="input-wrapper input relative w-full">
    <label v-if="label" :for="id" :class="classesLabel">{{ label }}</label>
    <input
      :id="id"
      v-bind="$attrs"
      class="w-full"
      :class="[
        errors.length && 'border-red',
        classesInput,
        $attrs?.disabled && 'bg-gray-100 text-gray-400',
      ]"
      :type="type"
      :placeholder="placeholder"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)" />
    <Transition name="nested">
      <button
        v-if="clear && modelValue.length"
        class="absolute right-3 top-1/2 -translate-y-1/2 cursor-pointer lg:hover:opacity-80"
        @click="$emit('clear')"
        aria-label="Clear">
        <Icon name="close" class="h-6 w-6 text-plum" />
      </button>
    </Transition>

    <TransitionGroup
      v-show="errors.length"
      name="nested"
      tag="ul"
      class="text-red first-letter:capitalize">
      <li v-for="error in errors" :key="error.$uid">
        {{ error.$message.replace("Value", error.$property) }}
      </li>
    </TransitionGroup>
  </div>
</template>

<script>
export default {
  emits: ["update:modelValue", "clear"],
  props: {
    modelValue: {
      type: [String, Number],
      default: "",
    },
    errors: {
      type: Array,
      required: false,
      default: () => [],
    },
    label: {
      type: String,
      required: false,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "text",
    },
    placeholder: {
      type: String,
      required: true,
    },
    classesInput: {
      type: String,
      required: false,
    },
    classesLabel: {
      type: String,
      required: false,
    },
    clear: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
